import axios from "axios"

export const state = {
   playlist: {},
   playlistLoading: false
}
export const getters = {
   get_playlist: (state) => state.playlist,
   get_playlist_loading: (state) => state.playlistLoading,

}
export const mutations = {
   set_playlist: (state, data) => state.playlist = data,
   set_playlist_loading: (state, data) => state.playlistLoading = data

}
export const actions = {
   async fetchPlaylist({ commit }) {
      try {
        
         commit('set_playlist_loading', true)
         var channelId = localStorage.getItem("channelId")
         var apiKey = localStorage.getItem("apiKey")
         var response = await axios.get(`https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=${channelId}&maxResults=10&key=${apiKey}`)
         if (response.status == 200) {
            commit('set_playlist', response.data)
         }
      } catch (e) {
         console.warn(e)
      } finally {
         commit('set_playlist_loading', false)
      }
   }
}
