import { axiosApi } from "@/config/axios"

export const state = {
   theme : {},
   themeLoading : false
}
export const getters = {
   get_theme : (state)=>state.theme,
   get_theme_loading : (state)=>state.themeLoading,

}
export const mutations = {
   set_theme :(state,data) => state.theme = data,
   set_theme_loading :(state,data) => state.themeLoading = data

}
export const actions = {
   async fetchTheme({commit}){
       try{
          commit('set_theme_loading',true)
           var response = await axiosApi.get("theme")
           console.warn(response.data)
       if(response.status==200){
          
           commit('set_theme',response.data)
       }
       } catch(e){
           console.warn(e)
       }finally{
        commit('set_theme_loading',false)
       }
   }
}