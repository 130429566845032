import { axiosApi } from "@/config/axios"

export const state = {
   events : {},
   eventsLoading : false
}
export const getters = {
   get_events : (state)=>state.events,
   get_events_loading : (state)=>state.eventsLoading,

}
export const mutations = {
   set_events :(state,data) => state.events = data,
   set_events_loading :(state,data) => state.eventsLoading = data

}
export const actions = {
   async fetchEvents({commit}){
       try{
          commit('set_events_loading',true)
           var response = await axiosApi.get("events")
           console.warn(response.data.data)
       if(response.status==200){
          
           commit('set_events',response.data.data)
       }
       } catch(e){
           console.warn(e)
       }finally{
        commit('set_events_loading',false)
       }
   }
}
